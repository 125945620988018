const Path = require('./path');

module.exports = {
  /**
   * Format the station data for presentation as cards.
   *
   * @param data
   * @returns {{image: *, path: *, rounded: boolean, titlePrefix: string, title: *}[]}
   */
  prepareAllPresenterData: data => {
    return data.allPresenter.edges.map(row => {
      return {
        image: row.node.field_values.image,
        title: row.node.title,
        rounded: true,
        href: Path.getPresenterPath(row.node.title),
      };
    });
  },
};
