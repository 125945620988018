import React from 'react';
import { graphql } from 'gatsby';
import Path from '../services/path';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';
import { prepareAllPresenterData } from '../services/presenter-helper';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';

const Presenters = ({ data }) => {
  const breadcrumbs = [
    { name: 'Radio', path: Path.basePath },
    { name: 'Presenters', path: null },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title="Presenters" />

      <SlimlineBannerBlock background="background--tertiary-three" heading="Presenters" />

      <CardGroup isSlider={false} cards={prepareAllPresenterData(data)} customClasses="row--padding-y o-card-group--rounded" />
    </Layout>
  );
};

export default Presenters;

export const presentersQuery = graphql`
  query Presenters {
    allPresenter(filter: { active: { eq: true } }, sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          field_values {
            image
          }
        }
      }
    }
  }
`;
